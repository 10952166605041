$exclude-pink: #dc5e94;
$include-green: #3fa46f;

h3 {
  &.exclude {
    color: $exclude-pink;
    border-bottom: $exclude-pink solid;
  }

  &.include {
    color: $include-green;
    border-bottom: $include-green solid;
  }
}

/* You can add global styles to this file, and also import other style files */
@import "@design-factory/design-factory/design-factory";
@import "partials/colors";

.btn-criteria-lg {
    border-radius: 25px;
    width: 12.6rem;
}

.btn-criteria {
    border-radius: 25px;
    width: 8rem;
}

// Forms. Custom class is used to avoid styling all forms (skip for login form for example)
form.styled-form {
  label:not(.form-check-label) {
    font-weight: 500;
  }

  .mandatory {
    color: red;
  }

  .invalid {
    color: #ec0e0e;
    font-size: 0.875rem;
  }
}
